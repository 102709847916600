/* eslint-disable */
import PermissionChecker from '@/security/permission-checker';

export class VehiclePermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.create = permissionChecker.match('createVehicle');
    this.read = permissionChecker.match('viewVehicles');
    this.destroy = permissionChecker.match('deleteVehicle');
    this.print = permissionChecker.match('vehiclePrint');
    this.export = permissionChecker.match('vehicleExport');
  }
}
