<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row mt-8">
      <h2 class="text-lg font-medium">{{ i18n('vehicle.title') }}</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div
        class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-end"
      >
        <div class="flex mt-5 sm:mt-0 gap-2">
          <button
            v-if="hasPermissionToCreate"
            class="btn bg-theme-36 text-white sm:w-auto pb-1 pt-1"
            @click="$router.push('/vehicles/new')"
          >
            <PlusIcon class="w-5 h-5 mr-2 ml-2" /> {{ i18n('common.create') }}
          </button>
          <app-action-toolbar
            @onPrint="onPrint()"
            @onExportXlsx="onExportXlsx()"
            @onDownloadPdf="onDownloadPdf()"
            :printPermission="hasPermissionToPrint"
            :exportPermission="hasPermissionToExport"
            :canExportAll="false"
          ></app-action-toolbar>
        </div>
      </div>
      <div ref="table" class="relative">
        <loading-spinner
          type="half-circle"
          :duration="1000"
          :size="40"
          color="#4469A8"
          :loading="loading"
        />
        <div class="overflow-x-auto">
          <table
            class="table table-report sm:mt-2"
            id="tablePrint"
            ref="tableRef"
          >
            <thead>
              <tr>
                <th class="whitespace-nowrap" id="">#</th>
                <th
                  v-for="col in columns"
                  :key="col.name"
                  :id="col.name === 'action' ? 'ignore-2' : ''"
                  class="text-center whitespace-nowrap"
                >
                  {{ i18n(col.label) }}
                </th>
              </tr>
            </thead>
            <tbody v-if="rows.length">
              <tr
                v-for="(row, index) in rows"
                :key="index"
                class="intro-x"
                :class="{ removedRow: row.isRemoved }"
              >
                <td class="w-40">
                  <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                    {{ rowIndex(index) }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterLocalization(row, 'name') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterDate(row, 'createdAt') }}
                  </div>
                  <div class="font-small text-xs whitespace-nowrap mt-1">
                    {{ presenterTime(row, 'createdAt') }}
                  </div>
                </td>
                <td class="table-report__action w-56" id="ignore-6">
                  <div
                    class="flex justify-center items-center gap-3 text-theme-24"
                  >
                    <a
                      v-if="hasPermissionToDestroy"
                      href="javascript:;"
                      class="text-theme-24 flex items-center gap-2 whitespace-nowrap"
                      data-target="#delete-modal-preview"
                      data-toggle="modal"
                      @click="selectedId = row.id"
                      ><Trash2Icon class="w-4 h-4 mr-1 ml-1" />
                      {{ i18n('common.delete') }}</a
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-empty-page
            v-if="!rows.length && !loading"
            :label="i18n('empty.vehicle')"
            label-class="text-lg font-medium"
            icon-size="4x"
            class="box w-full py-64"
          ></app-empty-page>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Delete Modal -->
    <DeleteModal item="vehicle" @delete="doDestroy(selectedId)" />
    <!-- END: Delete Modal -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DeleteModal from '@/components/modals/delete-modal.vue'
import { VehiclePermissions } from './vehicle-permissions'
import { exportPrintMixin } from '@/mixins/export-print-mixin'
import { presenterMixin } from '@/mixins/presenter-mixin'

export default {
  mixins: [presenterMixin, exportPrintMixin],
  components: {
    DeleteModal
  },
  data() {
    return {
      // data important for Mixins
      tableRef: null,
      exportTableName: 'vehicles',
      excludedCols: [3],
      ignoreElements: ['ignore-2', 'ignore-6'],
      // component Data
      columns: [
        {
          name: 'name',
          field: 'name',
          label: 'vehicle.table.name',
          align: 'center'
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'vehicle.table.createdAt',
          align: 'center'
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      currentUser: 'auth/currentUser',
      rows: 'vehicle/list/rows',
      loading: 'vehicle/list/loading'
    }),
    hasPermissionToCreate() {
      return new VehiclePermissions(this.currentUser).create
    },
    hasPermissionToDestroy() {
      return new VehiclePermissions(this.currentUser).destroy
    },
    hasPermissionToPrint() {
      return new VehiclePermissions(this.currentUser).print
    },
    hasPermissionToExport() {
      return new VehiclePermissions(this.currentUser).export
    },
    rowIndex() {
      return index => {
        return index + 1
      }
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.vehicles')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.vehicles')
    }
  },
  async mounted() {
    await this.doFetch()
  },
  methods: {
    ...mapActions({
      doFetch: 'vehicle/list/doFetch',
      doDestroy: 'vehicle/list/doDestroy'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}
.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}
.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
